import { useEffect } from "react";
import Aos from "aos";
import Marquee from "react-fast-marquee";
import ImgLogoApp from "./assets/img/img-logo.png";
import ImgLogoApp2 from "./assets/img/img-logo-2.png";
import ImgAvailable1 from "./assets/img/img-avalanche.png";
import ImgAvailable2 from "./assets/img/img-dex-screener.png";
import ImgAvailable3 from "./assets/img/img-dex-tools.png";
import ImgAvailable4 from "./assets/img/img-full-trader.png";
import ImgAvailable5 from "./assets/img/img-pangolin.png";
import ImgAvailable6 from "./assets/img/img-uniswap.png";
import IconCard1 from "./assets/img/icon-card-1.png";
import IconCard2 from "./assets/img/icon-card-2.png";
import IconCard3 from "./assets/img/icon-card-3.png";
import IconH2U1 from "./assets/img/icon-h2u-1.png";
import IconH2U2 from "./assets/img/icon-h2u-2.png";
import IconH2U3 from "./assets/img/icon-h2u-3.png";
import IconSocial1 from "./assets/img/icon-discord.png";
import IconSocial2 from "./assets/img/icon-telegram.png";
import IconSocial3 from "./assets/img/icon-x.png";
import ImgBg from "./assets/img/img-bg.png";
import "./App.scss";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    Aos.init({
      duration: 1500,
    });
  }, []);
  return (
    <div className="App">
      <div className="bg-img">
        <img src={ImgBg} alt="" />
      </div>
      <div className="container">
        <header className="page-header">
          <div className="logo-app">
            <img src={ImgLogoApp} alt="logo" />
          </div>
          <nav className="nav-menu">
            <ul className="menu">
              <li>
                <a href="">Home</a>
              </li>
              <li>
                <a href="#about">About</a>
              </li>
              <li>
                <a href="#features">Features</a>
              </li>
              <li>
                <a href="#features">Social</a>
              </li>
            </ul>
          </nav>
          <a href="" className="btn-primary">
            Buy Now
          </a>
        </header>
        <main className="page-main">
          <section data-aos="fade-up" className="section-intro">
            <h1 className="title">
              Advanced technology revolution in trading on the
              <span> AVAX </span>
              chain
            </h1>
            <p className="des">
              Experience seamless and easy transactions right on Telegram BOT!
            </p>
            <a href="" className="btn-primary">
              Open BOT
            </a>
          </section>
          <section data-aos="fade-up" className="section-available">
            <h3 className="title">Available On</h3>
            <div className="list-available">
              <Marquee speed={40} gradient={false}>
                <div className="item-slide">
                  <img src={ImgAvailable1} alt="Image 1" />
                </div>
                <div className="item-slide">
                  <img src={ImgAvailable2} alt="Image 2" />
                </div>
                <div className="item-slide">
                  <img src={ImgAvailable3} alt="Image 3" />
                </div>
                <div className="item-slide">
                  <img src={ImgAvailable4} alt="Image 4" />
                </div>
                <div className="item-slide">
                  <img src={ImgAvailable5} alt="Image 5" />
                </div>
                <div className="item-slide">
                  <img src={ImgAvailable6} alt="Image 6" />
                </div>
              </Marquee>
            </div>
          </section>
          <section id="about" data-aos="fade-up" className="section-about">
            <h3 className="title">
              Professional tool exclusively for<span> Avalanche </span>
            </h3>
            <p className="des">
              Using advanced technology with basic to advanced functions,
              providing maximum support in users' trading activities.
            </p>
            <div className="list-card">
              <div className="item-card">
                <div className="card-img">
                  <img src={IconCard1} alt="" />
                </div>
                <h3 className="card-title">Protect Transactions</h3>
                <p className="card-des">
                  A design wizard that delivers top-notch masterpieces whenever
                  you crave it!
                </p>
              </div>
              <div className="item-card">
                <div className="card-img">
                  <img src={IconCard2} alt="" />
                </div>
                <h3 className="card-title">Protect Transactions</h3>
                <p className="card-des">
                  A design wizard that delivers top-notch masterpieces whenever
                  you crave it!
                </p>
              </div>
              <div className="item-card">
                <div className="card-img">
                  <img src={IconCard3} alt="" />
                </div>
                <h3 className="card-title">Protect Transactions</h3>
                <p className="card-des">
                  A design wizard that delivers top-notch masterpieces whenever
                  you crave it!
                </p>
              </div>
            </div>
          </section>
          <section
            id="features"
            data-aos="fade-up"
            className="section-how-it-work"
          >
            <div className="top">
              <h3 className="title">What does AVAXGUN have?</h3>
              <p className="des">
                Seamlessly trade on Avalanche with AVAXBOT right on Telegram
                with an intuitive and easy-to-use interface
              </p>
            </div>
            <div className="list-card">
              <div className="card-item">
                <div className="card-img">
                  <img src={IconH2U1} alt="" />
                </div>
                <div className="content">
                  <h4 className="title">Professional Trading BOT</h4>
                  <p className="des">
                    Trade directly on Telegram BOT without visiting exchanges
                    with complicated account creation processes and slow trading
                    experiences.
                  </p>
                </div>
              </div>
              <div className="card-item">
                <div className="content">
                  <h4 className="title">Light Speed Sniper BOT</h4>
                  <p className="des">
                    Use powerful and private Nodes with the transaction speeds
                    in milliseconds. The sniper feature will help you assert
                    your position in trading.
                  </p>
                </div>
                <div className="card-img">
                  <img src={IconH2U2} alt="" />
                </div>
              </div>
              <div className="card-item">
                <div className="card-img">
                  <img src={IconH2U3} alt="" />
                </div>
                <div className="content">
                  <h4 className="title">Trading Monitor BOT</h4>
                  <p className="des">
                    Monitor live transactions on the Telegram Bot through the
                    monitoring feature. Price movements are updated visually in
                    real-time.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section id="social" data-aos="fade-up" className="section-join-us">
            <div className="content">
              <h3 className="title">
                Join us to experience trading at unlimited speed
              </h3>
              <p className="des">
                Let's discuss with the strong trader community using AVAXBOT.
              </p>
              <ul className="socials">
                <li>
                  <a href="" target="_blank" rel="noopener noreferrer">
                    <img src={IconSocial1} alt="" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://t.me/AvaxGun"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={IconSocial2} alt="" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/AvaxGun"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={IconSocial3} alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </section>
        </main>
        <footer className="page-footer">
          <div className="logo-footer">
            <img src={ImgLogoApp2} alt="" />
          </div>
          <nav className="nav-menu">
            <ul className="menu">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="#about">About</a>
              </li>
              <li>
                <a href="#features">Features</a>
              </li>
              <li>
                <a href="#social">Social</a>
              </li>
            </ul>
          </nav>
          <p className="copyright">AVAXBOT - Copyright all rights reserved</p>
        </footer>
      </div>
    </div>
  );
}

export default App;
